import { request, success, failure, MAIN_DASHBOARD, ANC_DASHBOARD } from "./utilities";
import {
  getStatsApi,
  getHistoryGraphApi,
  getCaseProfileTableApi,
  getSmsNudgesListApi,
  exportHistoryGraphDataApi,
  getAllCountApi,
  getNotifyVisitorsListApi,
  exportNotifyUserDataApi,
  sendNotifiedUserManualMessageApi,
  getIVRListingApi,
  exportIVRDataApi,
  getGenericDashboardStatsApi,
  getExportGenericDashboardStatsApi,
  getBatchListApi,
  addBatchApi,
  deleteBatchApi,
  getGlobalPlusDashboardApi,
} from "../api";
import { downloadBase64File } from "../../app/constant";

export function getStateAction(filters, urlState, moveToNext) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_STATS_REQUEST));
    getStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getVisitSummaryStateAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getGraphDataAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_GRAPH_DATA_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_GRAPH_DATA_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_GRAPH_DATA_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_GRAPH_DATA_FAILURE, error.message));
      }
    );
  };
}
export function exportReportStateAction(filters, moveToNext, urlState) {
  let originalString = urlState;
  let fileName = originalString.replace("Export", "");
  let randomNumber = Math.floor(Math.random() * 100);
  fileName += randomNumber;
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_REQUEST));
    getExportGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data !== undefined) {
          dispatch(
            success(
              ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_SUCCESS,
              response.data
            )
          );
          downloadBase64File(response.data, fileName + ".xlsx");

        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getModernBirthSpacingSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_MODERN_BIRTH_SPACING_STATS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_MODERN_BIRTH_SPACING_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_MODERN_BIRTH_SPACING_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_MODERN_BIRTH_SPACING_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getAnemiaandTreatmentSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getDiarrhoeaSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_DIARRHOEA_SUMMARY_STATS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_DIARRHOEA_SUMMARY_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_DIARRHOEA_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_DIARRHOEA_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getMalariaSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_MALARIA_SUMMARY_STATS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_MALARIA_SUMMARY_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_MALARIA_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_MALARIA_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getPlasmodiumSpeciesSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getRapeAndGBVCasesSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getOutcomeSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_OUTCOME_SUMMARY_STATS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_OUTCOME_SUMMARY_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_OUTCOME_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_OUTCOME_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getUnderFiveMalariaSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getLDMImmunizationAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_LDM_IMMUNIZATION_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_LDM_IMMUNIZATION_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_LDM_IMMUNIZATION_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_LDM_IMMUNIZATION_FAILURE, error.message));
      }
    );
  };
}
export function getLDMMotherAtDischargeAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_LDM_MOTHER_DISCHARGE_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_LDM_MOTHER_DISCHARGE_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_LDM_MOTHER_DISCHARGE_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_LDM_MOTHER_DISCHARGE_FAILURE, error.message));
      }
    );
  };
}
export function getLDMBabyAtDischargeAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_LDM_BABY_DISCHARGE_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_LDM_BABY_DISCHARGE_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_LDM_BABY_DISCHARGE_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_LDM_BABY_DISCHARGE_FAILURE, error.message));
      }
    );
  };
}
export function getLDMPMTCTSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_LDM_PMTCT_SUMMARY_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_LDM_PMTCT_SUMMARY_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_LDM_PMTCT_SUMMARY_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_LDM_PMTCT_SUMMARY_FAILURE, error.message));
      }
    );
  };
}
export function getLDMARTProphylaxisAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_LDM_ART_PROPHYLAXIS_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_LDM_ART_PROPHYLAXIS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_LDM_ART_PROPHYLAXIS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_LDM_ART_PROPHYLAXIS_FAILURE, error.message));
      }
    );
  };
}
export function getLDReferralSummaryAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_LDM_REFERRAL_SUMMARY_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_LDM_REFERRAL_SUMMARY_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_LDM_REFERRAL_SUMMARY_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_LDM_REFERRAL_SUMMARY_FAILURE, error.message));
      }
    );
  };
}

export function getHistoryGraph(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_HISTORY_GRAPH_REQUEST));
    getHistoryGraphApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_HISTORY_GRAPH_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_HISTORY_GRAPH_FAILURE,
              response.data.message
            )
          );
          // Notificiation(response.data.message, "error")
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_HISTORY_GRAPH_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getCaseProfileAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_REQUEST));
    getCaseProfileTableApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_FAILURE, error.message)
        );
      }
    );
  };
}
export function getBatchListingAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_BATCH_LIST_REQUEST));
    getBatchListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_BATCH_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_BATCH_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.GET_BATCH_LIST_FAILURE, error.message)
        );
      }
    );
  };
}
export function addBatchgAction(data, onSuccess) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.ADD_BATCH_REQUEST));
    addBatchApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.ADD_BATCH_SUCCESS,
              response.data.data
            )
          );
          if (onSuccess) {
            onSuccess()
          }
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.ADD_BATCH_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.ADD_BATCH_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function deleteBatchgAction(filters, modalClose) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.DELETE_BATCH_REQUEST));
    deleteBatchApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.DELETE_BATCH_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )

          );
          if (modalClose) {
            modalClose()
          }
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.DELETE_BATCH_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.DELETE_BATCH_FAILURE, error.message)
        );
      }
    );
  };
}
export function updateBatchgAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.UPDATE_BATCH_REQUEST));
    getBatchListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.UPDATE_BATCH_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.UPDATE_BATCH_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.UPDATE_BATCH_FAILURE, error.message)
        );
      }
    );
  };
}

export function getAllCountAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_ALL_COUNT_REQUEST));
    getAllCountApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_ALL_COUNT_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_ALL_COUNT_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_ALL_COUNT_FAILURE, error.message));
      }
    );
  };
}

export function getIVRListingList(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_IVR_LIST_REQUEST));
    getIVRListingApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_IVR_LIST_SUCCESS, response.data.data, {
              count: response.data.totalRecords,
            })
          );
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_IVR_LIST_FAILURE, response.data.message)
          );
          // Notificiation(response.data.message, "error")
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_IVR_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function getSmsNudgesList(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_REQUEST));
    getSmsNudgesListApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_FAILURE,
              response.data.message
            )
          );
          // Notificiation(response.data.message, "error")
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getNotifyVisitorsList(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_REQUEST));
    getNotifyVisitorsListApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function exportHistoryGraphData(
  filterData,
  fileName,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_REQUEST));
    exportHistoryGraphDataApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function exportNotifyUserData(filterData, fileName, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_REQUEST));
    exportNotifyUserDataApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}


export function exportIVRData(filterData, fileName, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(MAIN_DASHBOARD.EXPORT_IVR_DATA_REQUEST));
    exportIVRDataApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                MAIN_DASHBOARD.EXPORT_IVR_DATA_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              MAIN_DASHBOARD.EXPORT_IVR_DATA_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function sendNotifiedUserManualMessageActions(data, moveToNext) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_REQUEST));
    sendNotifiedUserManualMessageApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_SUCCESS,
              response.data
            )
          );
          moveToNext();
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getGlobalPlusDashboardDataAction(baseUrl, filters, moveToNext, urlState, key) {
  debugger
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_SECTION_GRAPH_LISTING_REQUEST, key));
    getGlobalPlusDashboardApi(baseUrl, filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_SECTION_GRAPH_LISTING_SUCCESS, response.data.data, { count: response.data.totalRecords }, key)
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_SECTION_GRAPH_LISTING_FAILURE, response.data.message, key)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_SECTION_GRAPH_LISTING_FAILURE, error.message, key));
      }
    );
  };
}
